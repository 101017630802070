<template>
  <div>
    <section>
      <h3 class="page-header py-3">Select available widgets</h3>
      <b-form
        id="applicable-widgets-form"
        name="applicable-widgets-form"
        autocomplete="off"
        @submit.prevent="onSubmitApplicableWidgets"
        novalidate
      >
        <b-form-row
          v-for="(widget, index) in widgetTypes.applicableWidgets"
          :key="index"
          class="applicable-widget-types"
        >
          <b-col cols="12" sm="7" md="6" lg="2">
            <b-form-group>
              <b-form-checkbox
                v-model="widget.isSelected"
                @change="applicableWidgetChecked($event, widget)"
                :name="'applicable-widget-checkbox-' + index"
                class="theme-font-bold mt-3"
              >
                {{ widget.widgetCategoryName }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="7" md="6" lg="2">
            <b-form-group class="mt-3">Widget #</b-form-group></b-col
          >
          <b-col cols="12" sm="7" md="6" lg="3">
            <b-form-group>
              <div class="floating-input-field">
                <b-form-input
                  :id="'applicable-widget-count-' + index"
                  type="number"
                  placeholder=" "
                  v-numberOnly
                  v-model.number="widget.allowedCnt"
                  :disabled="!widget.isSelected"
                  required
                ></b-form-input>
                <label :for="'applicable-widget-count-' + index"
                  >Max allowed count</label
                >
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="validateNumber(widget) && formSubmitted"
                  >Max allowed count should be number only & greater than
                  0.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            v-if="widget.isSelected && widget.configurations.length"
          >
            <b-form-row v-if="widget.widgetCategoryId == 8">
              <b-col class="font-14">
                <b-form-group>
                  <InfoCircelYellow class="mr-1 mt-n1" />
                  Please provide the following configuration details if the city
                  is using Calendar for Waste collection schedule
                </b-form-group></b-col
              >
            </b-form-row>
            <b-form-row>
              <b-col class="theme-font-medium">Configurations:</b-col>
            </b-form-row>
            <b-form-row
              v-for="(config, childIndex) in widget.configurations"
              :key="childIndex"
            >
              <b-col cols="12" sm="7" md="6" lg="2"></b-col>
              <b-col cols="12" sm="7" md="6" lg="2">
                <b-form-group class="mt-3 theme-font-medium">{{
                  config.title
                }}</b-form-group></b-col
              >
              <b-col
                cols="12"
                sm="12"
                md="6"
                lg="3"
                v-if="widget.widgetCategoryId != 8"
              >
                <b-form-group>
                  <div class="floating-input-field">
                    <b-form-input
                      :id="`${config.title}-${widget.widgetCategoryId}-${childIndex}`"
                      type="text"
                      placeholder=" "
                      v-model="config.value"
                      required
                    ></b-form-input>
                    <label
                      :for="`${config.title}-${widget.widgetCategoryId}-${childIndex}`"
                      >{{ config.title }}</label
                    >
                    <b-form-invalid-feedback
                      class="d-block"
                      v-if="!config.value && formSubmitted"
                      >{{ config.title }} required</b-form-invalid-feedback
                    >
                  </div>
                </b-form-group>
              </b-col>
              <template v-else>
                <b-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="3"
                  v-if="config.title == 'Calendar'"
                >
                  <b-form-group>
                    <div class="floating-select-field">
                      <v-select
                        label="calendarName"
                        append-to-body
                        :disabled="!myWasteCalendarList.length"
                        :options="myWasteCalendarList"
                        v-model="config.value"
                        @open="focusedMyWasteCalendar = true"
                        @close="focusedMyWasteCalendar = false"
                        :class="{
                          'is-value-exist':
                            config.value || focusedMyWasteCalendar,
                        }"
                      >
                        <template slot="no-options" slot-scope="{ search }">
                          {{
                            search
                              ? `No calendar found.`
                              : `Start typing calendar, and select it from the
                      dropdown.`
                          }}
                        </template>
                        <template slot="option" slot-scope="option">
                          {{ option.calendarName }}
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          {{ option.calendarName }}
                        </template>
                      </v-select>
                      <label for="select-calendar">{{ config.title }}</label>
                      <b-form-invalid-feedback
                        class="d-block"
                        v-if="
                          !config.value &&
                          formSubmitted &&
                          myWasteConfigToValidate
                        "
                        >{{ config.title }} required</b-form-invalid-feedback
                      >
                    </div>
                  </b-form-group>
                </b-col>
                <template v-else>
                  <b-col cols="12" sm="12" md="6" lg="3">
                    <b-form-group>
                      <div class="floating-input-field">
                        <b-form-input
                          :id="`${config.title}-${widget.widgetCategoryId}-${childIndex}`"
                          type="text"
                          placeholder=" "
                          @input="clearCalendarData(widget.configurations)"
                          v-model="config.value"
                          required
                        ></b-form-input>
                        <label
                          :for="`${config.title}-${widget.widgetCategoryId}-${childIndex}`"
                          >{{ config.title }}</label
                        >
                        <b-form-invalid-feedback
                          class="d-block"
                          v-if="
                            !config.value &&
                            formSubmitted &&
                            myWasteConfigToValidate
                          "
                          >{{ config.title }} required</b-form-invalid-feedback
                        >
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="12"
                    md="6"
                    lg="3"
                    v-if="config.title == 'Identity'"
                  >
                    <b-form-group>
                      <b-button
                        type="button"
                        v-activeBlur
                        variant="secondary"
                        @click="getMyWasteCalendars(widget.configurations)"
                        :disabled="disableGetCalendar(widget.configurations)"
                        >Get calendar
                      </b-button>
                    </b-form-group>
                  </b-col>
                </template>
              </template>
            </b-form-row>
          </b-col>
        </b-form-row>
        <b-form-row class="my-4">
          <b-col>
            <b-button
              type="submit"
              v-activeBlur
              variant="primary"
              :disabled="saveLoadingIcon"
              >Save
              <b-spinner
                v-if="saveLoadingIcon"
                label="Spinning"
                small
                class="ml-2"
              ></b-spinner
            ></b-button>
            <b-button
              type="button"
              v-activeBlur
              class="ml-3"
              @click="resetApplicableWidgetsForm"
              variant="outline-secondary"
              >Cancel</b-button
            >
          </b-col>
        </b-form-row>
      </b-form>
    </section>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash'
import { useValidateFields } from '@/composables/useValidateFields'
import InfoCircelYellow from '../../assets/svg/info-circle-yellow.svg'
export default {
  name: 'WidgetSetup',
  setup() {
    const { scrollToErrorMessage } = useValidateFields()

    return { scrollToErrorMessage }
  },
  data () {
    return {
      formSubmitted: false,
      saveLoadingIcon: false,
      cloneWidgetTypes: {
        applicableWidgets: []
      },
      widgetTypes: {
        applicableWidgets: []
      },
      focusedMyWasteCalendar: false,
      myWasteConfigToValidate: false,
      getMyWasteCalendarLoader: false,
      myWasteCalendarList: []
    }
  },
  components: {
    InfoCircelYellow
  },
  computed: {
    disableGetCalendar () {
      return (configurations) => {
        return configurations.some(
          (config) => !config.value && config.title !== 'Calendar'
        )
      }
    }
  },
  mounted () {
    this.getApplicableWidgets()
  },
  methods: {
    showToaster (message, key, type) {
      this.$store.commit('common/setCustomToastData', {
        message: message,
        key: key,
        type: type
      })
    },
    applicableWidgetChecked (event, widget) {
      if (!event) {
        const payload = {
          tenantID: this.$route.params.tenantID,
          widgetCategoryId: widget.widgetCategoryId
        }
        this.$store
          .dispatch('cities/isActiveWidgetNotExist', payload)
          .then((response) => {
            if (response.data && Object.hasOwn(response.data, 'errorMessage')) {
              this.showToaster(response.data.errorMessage, false, 'danger')
              this.$nextTick(() => {
                widget.isSelected = true
                const checkedWidget =
                  this.cloneWidgetTypes.applicableWidgets.find(
                    (wid) => wid.widgetCategoryId === widget.widgetCategoryId
                  )
                widget.allowedCnt = checkedWidget
                  ? checkedWidget.allowedCnt
                  : widget.allowedCnt
              })
            } else if (response.data) {
              widget.configurations.forEach((config) => (config.value = ''))
            } else {
              this.showToaster(false, 'FAILED', 'danger')
              widget.configurations.forEach((config) => (config.value = ''))
            }
          })
      }
    },
    getApplicableWidgets () {
      this.$store
        .dispatch('cities/getApplicableWidgets', this.$route.params.tenantID)
        .then((response) => {
          this.widgetTypes = cloneDeep(response.data)
          const myWasteWidget = this.widgetTypes.applicableWidgets.find(
            (widget) => widget.widgetCategoryId === 8 && widget.isSelected
          )
          if (myWasteWidget) {
            this.myWasteConfigConversion('json')
            this.getMyWasteCalendars(myWasteWidget.configurations)
          }
          this.cloneWidgetTypes = cloneDeep(this.widgetTypes)
        })
        .catch(() => {
          this.resetApplicableWidgetsForm()
          this.showToaster(false, 'ERROR_APPLICABLE_WIDGETS', 'danger')
        })
    },
    resetApplicableWidgetsForm () {
      this.widgetTypes = cloneDeep(this.cloneWidgetTypes)
      const myWasteWidget = this.widgetTypes.applicableWidgets.find(
        (widget) => widget.widgetCategoryId === 8 && widget.isSelected
      )
      if (myWasteWidget) {
        this.getMyWasteCalendars(myWasteWidget.configurations)
      }
    },
    validateNumber (widget) {
      if (widget.isSelected) {
        this.$set(
          widget,
          'error',
          !(widget.allowedCnt > 0 && Number.isInteger(widget.allowedCnt))
        )
      } else {
        this.$set(widget, 'error', false)
        this.$set(widget, 'allowedCnt', 0)
      }
      return widget.error
    },
    clearCalendarData (configurations) {
      this.myWasteCalendarList = []
      const calendarIndex = configurations.findIndex(
        (config) => config.title === 'Calendar'
      )
      configurations[calendarIndex].value = ''
      this.myWasteConfigToValidate = configurations.some(
        (config) => config.value
      )
    },
    getMyWasteCalendars (configurations) {
      const calendarHost = configurations.find(
        (config) => config.title === 'CalendarHost'
      )
      const identity = configurations.find(
        (config) => config.title === 'Identity'
      )
      const postObj = {
        calendarHost: calendarHost ? calendarHost.value : null,
        identity: identity ? identity.value : null
      }
      if (postObj.calendarHost && postObj.identity) {
        this.getMyWasteCalendarLoader = true
        this.$store
          .dispatch('cities/getMywasteCalendars', postObj)
          .then((response) => {
            this.getMyWasteCalendarLoader = false
            if (response.data && Object.hasOwn(response.data, 'errorMessage')) {
              this.showToaster(response.data.errorMessage, false, 'danger')
            } else if (response.data) {
              this.myWasteCalendarList = response.data
            } else {
              this.showToaster(false, 'FAILED', 'danger')
            }
          })
          .catch((error) => {
            this.getMyWasteCalendarLoader = false
            this.showToaster(error.response.data, false, 'danger')
          })
      }
    },
    validateApplicableWidgetsForm () {
      this.widgetTypes.applicableWidgets.forEach((widget, index) => {
        this.validateNumber(widget)
      })
      const isError = this.widgetTypes.applicableWidgets.some((widget) => {
        if (widget.widgetCategoryId === 8 && widget.isSelected) {
          if (widget.configurations.some((config) => config.value)) {
            return widget.configurations.some((config) => !config.value)
          } else {
            return false
          }
        } else {
          return (
            widget.isSelected &&
            widget.configurations.some((config) => !config.value)
          )
        }
      })
      this.scrollToErrorMessage()
      return (
        !isError &&
        !this.widgetTypes.applicableWidgets.some(
          (widget) => widget.error === true
        )
      )
    },
    myWasteConfigConversion (conversionType) {
      const myWasteIndex = this.widgetTypes.applicableWidgets.findIndex(
        (widget) => widget.widgetCategoryId === 8 && widget.isSelected
      )
      if (myWasteIndex > -1) {
        const calendarIndex = this.widgetTypes.applicableWidgets[
          myWasteIndex
        ].configurations.findIndex((config) => config.title === 'Calendar')
        if (calendarIndex > -1) {
          const calendarValue =
            this.widgetTypes.applicableWidgets[myWasteIndex].configurations[
              calendarIndex
            ].value
          this.widgetTypes.applicableWidgets[myWasteIndex].configurations[
            calendarIndex
          ].value = calendarValue
            ? conversionType === 'string'
              ? JSON.stringify(calendarValue)
              : JSON.parse(calendarValue)
            : ''
        }
      }
    },
    onSubmitApplicableWidgets () {
      this.formSubmitted = true
      if (this.validateApplicableWidgetsForm()) {
        this.myWasteConfigConversion('string')
        this.saveLoadingIcon = true
        this.$store
          .dispatch('cities/updateApplicableWidgets', this.widgetTypes)
          .then((response) => {
            this.saveLoadingIcon = false
            if (response.data && Object.hasOwn(response.data, 'errorMessage')) {
              this.showToaster(response.data.errorMessage, false, 'danger')
            } else if (response.data) {
              this.cloneWidgetTypes = cloneDeep(this.widgetTypes)
              this.showToaster(false, 'UPDATE_APPLICABLE_WIDGETS', 'success')
            } else {
              this.showToaster(false, 'FAILED', 'danger')
            }
            this.myWasteConfigConversion('json')
          })
          .catch(() => {
            this.saveLoadingIcon = false
            this.myWasteConfigConversion('json')
            this.showToaster(false, 'FAILED', 'danger')
          })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
